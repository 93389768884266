import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useAction from 'utils/useAction'
import * as modalsActions from 'reducers/modals/actions'
import { lastModalType, lastModalData } from 'reducers/modals/selectors'
import { MODAL_TYPES } from 'const/modals'
import { Modal } from 'antd'
import { route } from 'selectors/routing'
import useModalCancel from 'helpers/hooks/useModalCancel'

import StoreChangesModal from './StoreChanges'
import CommentsModal from './Comments'
import InfoModal from './Info'
import PaymentModal from './Payment'
import ConfirmModal from './Confirm'
import ChoiceModal from './Choice'
import StoreImagesModal from './StoreImages'
import AdPackageModal from './AdPackage/AdPackage'
import ProductModal from './Product'
import RuleSlugModal from './RuleSlug'
import EditStoreContentModal from './EditStoreContent/EditStoreContent'
import ConvertToRetailerModal from './ConvertToRetailer'
import RestoreRetailerModal from './RestoreRetailer'
import AdminModal from './Admin'
import UserModal from './User'
import MergeUserModal from './MergeUser'
import CouponModal from './Coupon'
import CampaignModal from './Campaign'
import AppVersionModal from './AppVersion'
import MobileNotificationModal from './MobileNotification'
import CreateRetailerModal from './CreateRetailerModal/CreateRetailerModal'
import SkipChangesModal from './SkipChangesModal'
import LandingPageImages from './LandingPageImages'
import MarketingPages from './MarketingPages'
import SEOPage from './SEOPage'
import Giveaways from './Giveaways'
import resetUserPassword from './ResetUserPassword'
import SMSVerification from './SMSVerification'
import ShopModal from './Shops'
import BonusesModal from './Bonuses'
import ChangePassword from './ChangePassword'
import UserBonusesModal from './UserBonuses'
import TransactionDetailsModal from './TransactionDetails'
import PaymentPackage from './PaymentPackage'
import ShopCouponsSettings from './ShopCouponsSettings'
import ShopAdPackage from './ShopAdPackage/ShopAdPackage'
import ShopCampaign from './ShopCampaign'
import ShopOffers from './ShopOffers'
import ShopGroupsCategoriesSettings from './ShopGroupsCategoriesSettings'
import ShopEnrichedData from './ShopEnrichedData'

const mapTypeToModal = {
  [MODAL_TYPES.COUPON]: CouponModal,
  [MODAL_TYPES.CAMPAIGN]: CampaignModal,
  [MODAL_TYPES.MOBILE_APP_VERSION]: AppVersionModal,
  [MODAL_TYPES.MOBILE_NOTIFICATION]: MobileNotificationModal,
  [MODAL_TYPES.PAYMENT]: PaymentModal,
  [MODAL_TYPES.PRODUCT]: ProductModal,
  [MODAL_TYPES.ADMIN]: AdminModal,
  [MODAL_TYPES.USER]: UserModal,
  [MODAL_TYPES.COMMENTS]: CommentsModal,
  [MODAL_TYPES.AD_PACKAGE]: AdPackageModal,
  [MODAL_TYPES.INFO]: InfoModal,
  [MODAL_TYPES.STORE_CHANGES]: StoreChangesModal,
  [MODAL_TYPES.CHOICE]: ChoiceModal,
  [MODAL_TYPES.MERGE_USER]: MergeUserModal,
  [MODAL_TYPES.RESET_USER_PASS]: resetUserPassword,
  [MODAL_TYPES.STORE_CHANGES_LEAVING_PAGE]: StoreChangesModal,
  [MODAL_TYPES.EDITORIAL_CHANGES]: StoreChangesModal,
  [MODAL_TYPES.EDIT_STORE_CONTENT]: EditStoreContentModal,
  [MODAL_TYPES.CONFIRM]: ConfirmModal,
  [MODAL_TYPES.STORE_IMAGES]: StoreImagesModal,
  [MODAL_TYPES.GLOBAL_UPDATE]: ConfirmModal,
  [MODAL_TYPES.CONVERT_TO_RETAILER]: ConvertToRetailerModal,
  [MODAL_TYPES.RESTORE_RETAILER]: RestoreRetailerModal,
  [MODAL_TYPES.RULE_SLUG]: RuleSlugModal,
  [MODAL_TYPES.CREATE_RETAILER]: CreateRetailerModal,
  [MODAL_TYPES.LANDING_PAGE_IMAGES]: LandingPageImages,
  [MODAL_TYPES.MARKETING_PAGES]: MarketingPages,
  [MODAL_TYPES.SEO_PAGE]: SEOPage,
  [MODAL_TYPES.GIVEAWAYS]: Giveaways,
  [MODAL_TYPES.SMS_VERIFICATION]: SMSVerification,
  [MODAL_TYPES.SHOPS]: ShopModal,
  [MODAL_TYPES.BONUSES]: BonusesModal,
  [MODAL_TYPES.CHANGE_PASSWORD]: ChangePassword,
  [MODAL_TYPES.USER_BONUSES]: UserBonusesModal,
  [MODAL_TYPES.TRANSACTION_DETAILS]: TransactionDetailsModal,
  [MODAL_TYPES.PAYMENT_PACKAGE]: PaymentPackage,
  [MODAL_TYPES.COUPONS_SETTINGS]: ShopCouponsSettings,
  [MODAL_TYPES.SHOP_AD_PACKAGE]: ShopAdPackage,
  [MODAL_TYPES.SHOP_CAMPAIGN_DEAL]: ShopCampaign,
  [MODAL_TYPES.SHOP_OFFERS]: ShopOffers,
  [MODAL_TYPES.SHOP_GROUPS_CATEGORIES]: ShopGroupsCategoriesSettings,
  [MODAL_TYPES.SHOP_ENRICHED_DATA]: ShopEnrichedData,
}

const mapTypeToTitle = {
  [MODAL_TYPES.ADMIN]: 'Admin',
  [MODAL_TYPES.PAYMENT]: 'Payment',
  [MODAL_TYPES.PRODUCT]: 'Product',
  [MODAL_TYPES.AD_PACKAGE]: 'Ad Package',
  [MODAL_TYPES.COMMENTS]: 'Comments',
  [MODAL_TYPES.USER]: 'User',
  [MODAL_TYPES.MERGE_USER]: 'Merge User',
  [MODAL_TYPES.RULE_SLUG]: 'Rule slug',
  [MODAL_TYPES.INFO]: 'Invalid data',
  [MODAL_TYPES.STORE_CHANGES]: 'Changes',
  [MODAL_TYPES.CHOICE]: 'Image upload',
  [MODAL_TYPES.STORE_CHANGES_LEAVING_PAGE]:
    'Save or discard changes before leaving the page',
  [MODAL_TYPES.EDIT_STORE_CONTENT]: 'Edit store content',
  [MODAL_TYPES.CONFIRM]: 'Confirm action',
  [MODAL_TYPES.STORE_IMAGES]: 'Store images',
  [MODAL_TYPES.GLOBAL_UPDATE]: 'Global retailer update',
  [MODAL_TYPES.CONVERT_TO_RETAILER]: 'Convert retailer',
  [MODAL_TYPES.RESTORE_RETAILER]: 'Restore retailer',
  [MODAL_TYPES.CREATE_RETAILER]: 'Create retailer',
  [MODAL_TYPES.MOBILE_APP_VERSION]: 'Mobile app version',
  [MODAL_TYPES.MOBILE_NOTIFICATION]: 'Mobile notification',
  [MODAL_TYPES.SEO_PAGE]: 'SEO page',
  [MODAL_TYPES.TRANSACTION_DETAILS]: 'Payment transaction',
  [MODAL_TYPES.SHOP_AD_PACKAGE]: 'Ad Package',
  [MODAL_TYPES.SHOP_CAMPAIGN_DEAL]: 'Campaign deal',
  [MODAL_TYPES.PAYMENT_PACKAGE]: 'Package',
}

const mapTypeToExtraProps = {
  [MODAL_TYPES.STORE_CHANGES]: {
    maskStyle: { background: 'rgb(38 107 156 / 45%)' },
  },
  [MODAL_TYPES.EDIT_STORE_CONTENT]: {
    style: { top: 20 },
    width: 1000,
  },
  [MODAL_TYPES.AD_PACKAGE]: {
    width: 1000,
  },
  [MODAL_TYPES.PRODUCT]: {
    width: 1000,
  },
  [MODAL_TYPES.COUPON]: {
    width: 800,
  },
  [MODAL_TYPES.SHOP_OFFERS]: {
    width: 800,
  },
  [MODAL_TYPES.ADMIN]: {
    width: 600,
  },
  [MODAL_TYPES.USER]: {
    width: 600,
  },
  [MODAL_TYPES.MERGE_USER]: {
    width: 600,
  },
  [MODAL_TYPES.RULE_SLUG]: {
    width: 600,
  },
  [MODAL_TYPES.MOBILE_APP_VERSION]: {
    width: 800,
  },
  [MODAL_TYPES.MOBILE_NOTIFICATION]: {
    width: 800,
  },
  [MODAL_TYPES.PAYMENT]: {
    width: 800,
  },
  [MODAL_TYPES.STORE_IMAGES]: {
    width: 1170,
  },
  [MODAL_TYPES.TRANSACTION_DETAILS]: {
    width: 700,
  },
  [MODAL_TYPES.MARKETING_PAGES]: {
    width: 700,
  },
  [MODAL_TYPES.SEO_PAGE]: {
    style: { top: 20 },
    width: 1000,
  },
  [MODAL_TYPES.STORE_CHANGES_LEAVING_PAGE]: {
    width: 600,
    maskStyle: { background: 'rgb(38 107 156 / 45%)' },
  },
  [MODAL_TYPES.SHOP_GROUPS_CATEGORIES]: {
    width: 700,
  },
  [MODAL_TYPES.SHOP_ENRICHED_DATA]: {
    width: 800,
  },
}

const ModalsPortal = () => {
  const [showSkipChangesModal, setShowSkipChangesModal] = useState(false)
  const closeModal = useAction(modalsActions.close)
  const clearModalsStackFunc = useAction(modalsActions.clearModalsStack)
  const handleCloseSkipChangesModal = () => setShowSkipChangesModal(false)
  const handleOpenSkipChangesModal = () => setShowSkipChangesModal(true)

  const currentRoute = useSelector(route)
  const modalType = useSelector(lastModalType)
  const modalData = useSelector(lastModalData)
  const ModalContent = mapTypeToModal[modalType]
  const modalTitle = mapTypeToTitle[modalType]
  const modalExtraProps = mapTypeToExtraProps[modalType] || {}

  const [handleCancel, handleSkipChanges] = useModalCancel(
    modalType,
    modalData,
    closeModal,
    handleOpenSkipChangesModal
  )

  useEffect(() => {
    clearModalsStackFunc()
  }, [currentRoute, clearModalsStackFunc])

  return ModalContent ? (
    <>
      <Modal
        title={modalTitle}
        onCancel={handleCancel}
        open
        centered
        footer={null}
        {...modalExtraProps}
      >
        <ModalContent closeModal={handleCancel} {...modalData} />
      </Modal>
      {handleSkipChanges && (
        <SkipChangesModal
          show={showSkipChangesModal}
          callback={handleSkipChanges}
          closeModal={handleCloseSkipChangesModal}
        />
      )}
    </>
  ) : null
}

export default ModalsPortal
