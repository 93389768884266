const FIELD_NAMES = {
  installationDate: 'installationDate',
  totalUsers: 'totalUsers',
  totalUninstallations: 'totalUninstallations',
  totalActiveUsers: 'totalActiveUsers',
  usedCashBack: 'usedCashBack',
  monkeyHide: 'monkeyHide',
  monkeyKeep: 'monkeyKeep',
  monkeyError: 'monkeyError',
}

export const COLUMNS = [
  {
    title: 'Installation Date',
    dataIndex: FIELD_NAMES.installationDate,
    key: FIELD_NAMES.installationDate,
    width: 150,
    sorter: (a, b) =>
      new Date(a[FIELD_NAMES.installationDate]) -
      new Date(b[FIELD_NAMES.installationDate]), // Sort by date
  },
  {
    title: 'Total Users',
    dataIndex: FIELD_NAMES.totalUsers,
    key: FIELD_NAMES.totalUsers,
    width: 150,
    sorter: (a, b) => a[FIELD_NAMES.totalUsers] - b[FIELD_NAMES.totalUsers], // Sort by number
  },
  {
    title: 'Total Uninstallations',
    dataIndex: FIELD_NAMES.totalUninstallations,
    key: FIELD_NAMES.totalUninstallations,
    width: 180,
    sorter: (a, b) =>
      a[FIELD_NAMES.totalUninstallations] - b[FIELD_NAMES.totalUninstallations], // Sort by number
  },
  {
    title: 'Total Active Users',
    dataIndex: FIELD_NAMES.totalActiveUsers,
    key: FIELD_NAMES.totalActiveUsers,
    width: 170,
    sorter: (a, b) =>
      a[FIELD_NAMES.totalActiveUsers] - b[FIELD_NAMES.totalActiveUsers], // Sort by number
  },
  {
    title: 'Used Cash Back',
    dataIndex: FIELD_NAMES.usedCashBack,
    key: FIELD_NAMES.usedCashBack,
    width: 150,
    sorter: (a, b) => a[FIELD_NAMES.usedCashBack] - b[FIELD_NAMES.usedCashBack], // Sort by number
  },
  {
    title: 'Monkey Hide',
    dataIndex: FIELD_NAMES.monkeyHide,
    key: FIELD_NAMES.monkeyHide,
    width: 150,
    sorter: (a, b) => a[FIELD_NAMES.monkeyHide] - b[FIELD_NAMES.monkeyHide], // Sort by number
  },
  {
    title: 'Monkey Keep',
    dataIndex: FIELD_NAMES.monkeyKeep,
    key: FIELD_NAMES.monkeyKeep,
    width: 150,
    sorter: (a, b) => a[FIELD_NAMES.monkeyKeep] - b[FIELD_NAMES.monkeyKeep], // Sort by number
  },
  {
    title: 'Monkey Error',
    dataIndex: FIELD_NAMES.monkeyError,
    key: FIELD_NAMES.monkeyError,
    width: 150,
    sorter: (a, b) => a[FIELD_NAMES.monkeyError] - b[FIELD_NAMES.monkeyError], // Sort by number
  },
]
