export const MODAL_TYPES = {
  USER: 'user',
  RESET_USER_PASS: 'reset_user_pass',
  MERGE_USER: 'merge_user',
  ADMIN: 'admin',
  CONFIRM: 'confirm',
  PRODUCT: 'product',
  PAYMENT: 'payment',
  COUPON: 'coupon',
  CAMPAIGN: 'campaign',
  RULE_SLUG: 'rule_slug',
  MOBILE_APP_VERSION: 'mobile_app_version',
  MOBILE_NOTIFICATION: 'mobile_notification',
  GIVEAWAYS: 'giveaways',
  INFO: 'info',
  RESTORE_RETAILER: 'restore_retailer',
  GLOBAL_UPDATE: 'global_retailer_update',
  COMMENTS: 'store_comments',
  AD_PACKAGE: 'ad_package',
  CHOICE: 'choice',
  STORE_IMAGES: 'store_images',
  STORE_CHANGES: 'store_changes',
  STORE_CHANGES_LEAVING_PAGE: 'store_changes_leaving',
  EDITORIAL_CHANGES: 'editorial_changes',
  EDIT_STORE_CONTENT: 'edit_store_content',
  CONVERT_TO_RETAILER: 'convert_to_retailer',
  CREATE_RETAILER: 'create_retailer',
  LANDING_PAGE_IMAGES: 'landing_page_images',
  MARKETING_PAGES: 'marketing_pages',
  SEO_PAGE: 'product_families',
  SMS_VERIFICATION: 'sms_verification',
  SHOPS: 'shops',
  BONUSES: 'bonuses',
  CHANGE_PASSWORD: 'change_password',
  USER_BONUSES: 'user_bonuses',
  TRANSACTION_DETAILS: 'transaction_details',
  PAYMENT_PACKAGE: 'payment_package',
  COUPONS_SETTINGS: 'coupons_settings',
  SHOP_AD_PACKAGE: 'shop_ad_package',
  SHOP_CAMPAIGN_DEAL: 'shop_campaign_deal',
  SHOP_OFFERS: 'shop_offers',
  SHOP_GROUPS_CATEGORIES: 'shop_groups_categories',
  SHOP_ENRICHED_DATA: 'shop_enriched_data',
}
