import { ANY_ROLE, ROLES } from './roles'
import {
  DollarOutlined,
  TeamOutlined,
  SettingOutlined,
  LaptopOutlined,
  FundProjectionScreenOutlined,
  FireOutlined,
  StockOutlined,
  ShoppingCartOutlined,
  ExperimentOutlined,
} from '@ant-design/icons'
import React from 'react'

const ROUTES = {
  HOME: '/',
  RETAILERS: '/retailers',
  LOGIN: '/login',
  COUPONS: '/coupons',
  USERS: '/users',
  DELETED_STORES: '/deleted',
  RULE_SLUGS: '/rule_slugs',
  INSIGHTS: '/insights',
  PAYMENTS: '/payments',
  LINK_CONVERTER: '/link-converter',
  SETTINGS: '/settings',
  LANDING_PAGE_IMAGES: '/landing-page-images',
  MARKETING_PAGES: '/marketing-pages',
  SEO_PAGES: '/seo-pages',
  GIVEAWAYS: '/giveaways',
  FEATURES: '/features',
  EDITORIAL: '/editorial',
  AD_PACKAGES: '/ad_packages',
  CUSTOMER_SERVICE: '/customer_service',
  EMAIL_NOTIFICATIONS: '/email_notifications',
  MOBILE_APP_VERSION: '/mobile_app_versions',
  EXTENSION_VERSIONS: '/extension_versions',
  K_PROJECT: '/k_project',
  MOBILE_NOTIFICATIONS: '/mobile_notifications',
  PITA: '/pita',
  ROLES: '/roles',
  SHOP_DEV: '/shop-dev',
  ADMIN_LOGS: '/logs',
  BONUSES: '/bonuses',
  TRANSACTIONS_BY_DATE: '/transactions_by_date',
  PAYMENT_TRANSACTIONS: '/payment_transactions',

  CAMPAIGN_DEALS: '/campaign-deals',

  BASE_WEB_DASHBOARD: 'https://karmanow.com/dashboard',
}

export const ROUTES_PERMISSIONS = {
  [ROUTES.LOGIN]: ANY_ROLE,
  /**
   * Advanced
   */
  [ROUTES.ROLES]: ANY_ROLE,
  [ROUTES.PITA]: [ROLES.super],
  [ROUTES.ADMIN_LOGS]: [ROLES.super],

  /**
   * Development
   */
  [ROUTES.FEATURES]: [ROLES.super, ROLES.dev, ROLES.product],
  [ROUTES.EMAIL_NOTIFICATIONS]: [ROLES.super, ROLES.dev, ROLES.product],
  [ROUTES.MOBILE_APP_VERSION]: [ROLES.super, ROLES.dev, ROLES.product],
  [ROUTES.EXTENSION_VERSIONS]: [ROLES.admin],
  [ROUTES.MOBILE_NOTIFICATIONS]: [ROLES.super, ROLES.dev, ROLES.product],
  [ROUTES.SHOP_DEV]: [ROLES.super],
  /**
   * Customer Service
   */
  [ROUTES.USERS]: [
    ROLES.super,
    ROLES.customerService,
    ROLES.finance,
    ROLES.product,
  ],
  [ROUTES.CUSTOMER_SERVICE]: [
    ROLES.super,
    ROLES.customerService,
    ROLES.marketing,
    ROLES.finance,
    ROLES.product,
    ROLES.operations,
    ROLES.editorial,
  ],

  /**
   * Marketing
   */
  [ROUTES.LANDING_PAGE_IMAGES]: [
    ROLES.super,
    ROLES.customerService,
    ROLES.marketing,
  ],
  [ROUTES.MARKETING_PAGES]: [ROLES.super, ROLES.marketing],
  [ROUTES.LINK_CONVERTER]: [ROLES.super, ROLES.marketing],
  [ROUTES.GIVEAWAYS]: [
    ROLES.super,
    ROLES.marketing,
    ROLES.operations,
    ROLES.editorial,
  ],
  [ROUTES.AD_PACKAGES]: [
    ROLES.super,
    ROLES.marketing,
    ROLES.operations,
    ROLES.editorial,
    ROLES.finance,
  ],
  [ROUTES.BONUSES]: [ROLES.super, ROLES.marketing],

  /**
   * Editorial
   */
  [ROUTES.EDITORIAL]: [
    ROLES.super,
    ROLES.editorial,
    ROLES.marketing,
    ROLES.operations,
    ROLES.product,
  ],
  [ROUTES.SETTINGS]: [
    ROLES.super,
    ROLES.editorial,
    ROLES.marketing,
    ROLES.operations,
    ROLES.product,
  ],
  [ROUTES.INSIGHTS]: [
    ROLES.super,
    ROLES.editorial,
    ROLES.marketing,
    ROLES.operations,
    ROLES.product,
  ],
  [ROUTES.SEO_PAGES]: [
    ROLES.super,
    ROLES.editorial,
    ROLES.marketing,
    ROLES.operations,
    ROLES.product,
  ],

  /**
   * Sales
   */
  [ROUTES.RETAILERS]: [
    ROLES.super,
    ROLES.customerService,
    ROLES.marketing,
    ROLES.operations,
    ROLES.editorial,
    ROLES.finance,
  ],
  [ROUTES.DELETED_STORES]: [ROLES.super, ROLES.operations, ROLES.finance],
  [ROUTES.COUPONS]: [
    ROLES.super,
    ROLES.customerService,
    ROLES.operations,
    ROLES.editorial,
    ROLES.finance,
  ],
  [ROUTES.CAMPAIGN_DEALS]: [
    ROLES.super,
    ROLES.operations,
    ROLES.editorial,
    ROLES.finance,
  ],
  [ROUTES.RULE_SLUGS]: [ROLES.super, ROLES.operations],

  /**
   * Sales
   */
  [ROUTES.PAYMENTS]: ANY_ROLE,
  [ROUTES.TRANSACTIONS_BY_DATE]: [ROLES.super, ROLES.finance],
  [ROUTES.PAYMENT_TRANSACTIONS]: [ROLES.super, ROLES.finance],

  /**
   * Algorithms
   */
  [ROUTES.K_PROJECT]: [ROLES.super, ROLES.dev],

  [ROUTES.HOME]: ANY_ROLE,
}

export const HEADER_LINKS = [
  {
    icon: <SettingOutlined />,
    title: 'Advanced',
    titleRoute: ROUTES.ROLES,
    items: [ROUTES.ROLES, ROUTES.PITA, ROUTES.ADMIN_LOGS],
  },
  {
    icon: <LaptopOutlined />,
    title: 'Development',
    titleRoute: ROUTES.FEATURES,
    items: [
      ROUTES.FEATURES,
      ROUTES.EXTENSION_VERSIONS,
      ROUTES.MOBILE_APP_VERSION,
      ROUTES.MOBILE_NOTIFICATIONS,
      ROUTES.EMAIL_NOTIFICATIONS,
      ROUTES.SHOP_DEV,
    ],
  },
  {
    icon: <ExperimentOutlined />,
    title: 'Algorithms',
    titleRoute: ROUTES.A,
    items: [ROUTES.K_PROJECT],
  },
  {
    icon: <TeamOutlined />,
    title: 'Customer service',
    titleRoute: ROUTES.USERS,
    items: [ROUTES.USERS, ROUTES.CUSTOMER_SERVICE],
  },
  {
    icon: <ShoppingCartOutlined />,
    title: 'Shops',
    titleRoute: ROUTES.HOME,
    items: [ROUTES.HOME],
  },
  {
    icon: <FireOutlined />,
    title: 'Marketing',
    titleRoute: ROUTES.CUSTOMER_SERVICE,
    items: [
      ROUTES.LANDING_PAGE_IMAGES,
      ROUTES.MARKETING_PAGES,
      ROUTES.LINK_CONVERTER,
      ROUTES.GIVEAWAYS,
      ROUTES.AD_PACKAGES,
      ROUTES.BONUSES,
    ],
  },
  {
    icon: <FundProjectionScreenOutlined />,
    title: 'Editorial',
    titleRoute: ROUTES.EDITORIAL,
    items: [
      ROUTES.EDITORIAL,
      ROUTES.SETTINGS,
      ROUTES.INSIGHTS,
      ROUTES.SEO_PAGES,
    ],
  },
  {
    icon: <StockOutlined />,
    title: 'Sales',
    titleRoute: ROUTES.RETAILERS,
    items: [
      ROUTES.RETAILERS,
      ROUTES.DELETED_STORES,
      ROUTES.RULE_SLUGS,
      ROUTES.COUPONS,
      ROUTES.CAMPAIGN_DEALS,
    ],
  },
  {
    icon: <DollarOutlined />,
    title: 'Finance',
    titleRoute: ROUTES.PAYMENTS,
    items: [ROUTES.PAYMENTS, ROUTES.TRANSACTIONS_BY_DATE],
  },
]

export const ROUTE_TO_LABEL = {
  [ROUTES.HOME]: 'Shops',
  [ROUTES.RULE_SLUGS]: 'Rule Slugs',
  [ROUTES.DELETED_STORES]: 'Deleted Retailers',
  [ROUTES.SETTINGS]: 'Settings',
  [ROUTES.FEATURES]: 'Features',
  [ROUTES.COUPONS]: 'Coupons',
  [ROUTES.USERS]: 'Users',
  // [ROUTES.USER_BAN]: 'User Ban',
  [ROUTES.RETAILERS]: 'Retailers',
  [ROUTES.EDITORIAL]: 'Editorial',
  [ROUTES.AD_PACKAGES]: 'Ad Packages',
  [ROUTES.LINK_CONVERTER]: 'Link Converter',
  [ROUTES.CUSTOMER_SERVICE]: 'Customer Service',
  [ROUTES.INSIGHTS]: 'Insights',
  [ROUTES.LANDING_PAGE_IMAGES]: 'Landing Page Images',
  [ROUTES.MARKETING_PAGES]: 'Marketing Pages',
  [ROUTES.SEO_PAGES]: 'SEO Pages',
  [ROUTES.BONUSES]: 'Bonuses',
  [ROUTES.PAYMENTS]: 'Admin Payments',
  [ROUTES.ROLES]: 'Roles',
  [ROUTES.PITA]: 'Pita stats',
  [ROUTES.ADMIN_LOGS]: 'Logs',
  [ROUTES.EMAIL_NOTIFICATIONS]: 'Email Notifications',
  [ROUTES.MOBILE_APP_VERSION]: 'Mobile App Versions',
  [ROUTES.EXTENSION_VERSIONS]: 'Extension Versions',
  [ROUTES.K_PROJECT]: 'K Project',
  [ROUTES.MOBILE_NOTIFICATIONS]: 'Mobile Notifications',
  [ROUTES.GIVEAWAYS]: 'Giveaways Popups',
  [ROUTES.SHOP_DEV]: 'Shop Dev',
  [ROUTES.CAMPAIGN_DEALS]: 'Campaign Deals',
  [ROUTES.TRANSACTIONS_BY_DATE]: 'Transactions By Date',
}

export default ROUTES
